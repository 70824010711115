import React, { useEffect, useState } from 'react';

interface RecognitionItem {
  original: string;
  translated: string;
}

interface TranslationDisplayProps {
  recognitionList: RecognitionItem[];
}

const TranslationDisplay: React.FC<TranslationDisplayProps> = ({ recognitionList }) => {
  const [lastSpokenIndex, setLastSpokenIndex] = useState(-1);

  console.log(recognitionList);

  useEffect(() => {
    // Speak new translations
    if (recognitionList.length > lastSpokenIndex + 1) {
      for (let i = lastSpokenIndex + 1; i < recognitionList.length; i++) {
        speakTranslation(recognitionList[i].translated);
      }
      setLastSpokenIndex(recognitionList.length - 1);
    }
  }, [recognitionList, lastSpokenIndex]);

  const speakTranslation = (text: string) => {
    // Cancel any ongoing speech
    window.speechSynthesis.cancel();

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'en-US';
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className="translation-display">
      <h2>Recognized Text and Translations</h2>
      <ul className="recognition-list">
        {recognitionList.map((item, index) => (
          <li key={index} className="recognition-item">
            <div className="original">{item.original}</div>
            <div className="translated">{item.translated}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TranslationDisplay;