import React, { useState, useCallback } from 'react';
import './App.css';
import SpeechRecognitionComponent from './components/SpeechRecognition';
import TranslationDisplay from './components/TranslationDisplay';

interface RecognitionItem {
  original: string;
  translated: string;
}

function App() {
  const [recognitionList, setRecognitionList] = useState<RecognitionItem[]>([]);
  const [isTranslating, setIsTranslating] = useState(false);

  const handleTranscript = useCallback((transcript: string, translation: string) => {
    setIsTranslating(true);
    try {
      setRecognitionList(prevList => [...prevList, { original: transcript, translated: translation }]);
      console.log('New recognition:', { original: transcript, translated: translation });
    } finally {
      setIsTranslating(false);
    }
  }, []);

  return (
    <div className="App">
      <h1>실시간 음성 인식 및 번역기</h1>
      <p>아래의 버튼을 클릭하고 한국어로 말씀해 주세요. 인식된 텍스트와 영어 번역이 아래에 표시됩니다.</p>
      <SpeechRecognitionComponent onTranscript={handleTranscript} />
      {isTranslating && <p>번역 중...</p>}
      <TranslationDisplay recognitionList={recognitionList} />
    </div>
  );
}

export default App;
