import React, { useState, useEffect, useRef, useCallback } from 'react';
import { translateWithGemini } from '../services/geminiTranslator';

interface SpeechRecognitionProps {
  onTranscript: (transcript: string, translation: string) => void;
}

let recognitionInstance: SpeechRecognition | null = null;

const SpeechRecognitionComponent: React.FC<SpeechRecognitionProps> = ({ onTranscript }) => {
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [glossary, setGlossary] = useState<string[]>([]);
  const [newGlossaryItem, setNewGlossaryItem] = useState('');
  const restartAttempts = useRef(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const rafIdRef = useRef<number | null>(null);

  const initializeAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
      sourceRef.current.connect(analyserRef.current);
      analyserRef.current.fftSize = 2048;
      const bufferLength = analyserRef.current.frequencyBinCount;
      dataArrayRef.current = new Uint8Array(bufferLength);
    } catch (err) {
      console.error('Error initializing audio:', err);
      setError('마이크를 초기화할 수 없습니다. 마이크 권한을 확인해주세요.');
    }
  };

  const drawWaveform = () => {
    if (!canvasRef.current || !analyserRef.current || !dataArrayRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    if (!canvasCtx) return;

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

    canvasCtx.fillStyle = 'rgb(200, 200, 200)';
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
    canvasCtx.beginPath();

    const sliceWidth = WIDTH * 1.0 / dataArrayRef.current.length;
    let x = 0;

    for (let i = 0; i < dataArrayRef.current.length; i++) {
      const v = dataArrayRef.current[i] / 128.0;
      const y = v * HEIGHT / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();

    rafIdRef.current = requestAnimationFrame(drawWaveform);
  };

  const initializeSpeechRecognition = useCallback(() => {
    if (!('webkitSpeechRecognition' in window)) {
      console.error('웹 음성 인식이 지원되지 않습니다.');
      setError('브라우저가 음성 인식을 지원하지 않습니다.');
      return null;
    }

    if (!recognitionInstance) {
      console.log('SpeechRecognition 객체 생성');
      const SpeechRecognition = window.webkitSpeechRecognition;
      recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;
      recognitionInstance.lang = 'ko-KR';
      recognitionInstance.maxAlternatives = 1;

      recognitionInstance.onstart = () => {
        console.log('음성 인식 시작됨');
        setIsListening(true);
        setError(null);
        drawWaveform();
      };

      recognitionInstance.onaudiostart = () => {
        console.log('오디오 캡처 시작됨');
      };

      recognitionInstance.onspeechstart = () => {
        console.log('음성 감지됨');
        restartAttempts.current = 0;
      };

      recognitionInstance.onresult = async (event: SpeechRecognitionEvent) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }

        if (finalTranscript) {
          console.log('Final transcript:', finalTranscript);
          const translatedText = await translateWithGemini(finalTranscript.trim(), 'English', glossary);
          onTranscript(finalTranscript.trim(), translatedText);
        }
      };

      recognitionInstance.onspeechend = () => {
        console.log('음성 감지 종료됨');
      };

      recognitionInstance.onaudioend = () => {
        console.log('오디오 캡처 종료됨');
      };

      recognitionInstance.onend = () => {
        console.log('음성 인식 종료됨');
        setIsListening(false);
        if (rafIdRef.current) {
          cancelAnimationFrame(rafIdRef.current);
        }
        if (isListening && restartAttempts.current < 3) {
          console.log(`음성 인식 재시작 시도 (${restartAttempts.current + 1}/3)`);
          restartAttempts.current += 1;
          setTimeout(startRecognition, 1000); // Add a delay before restarting
        } else if (restartAttempts.current >= 3) {
          setError('음성이 감지되지 않았습니다. 마이크를 확인하고 다시 시도해주세요.');
        }
      };

      recognitionInstance.onerror = (event: SpeechRecognitionErrorEvent) => {
        console.error('음성 인식 오류:', event.error);
        if (event.error === 'no-speech') {
          console.log('음성이 감지되지 않음, 재시작 시도');
          if (restartAttempts.current < 3) {
            setTimeout(startRecognition, 1000); // Add a delay before restarting
          } else {
            setError('음성이 감지되지 않았습니다. 마이크를 확인하고 다시 시도해주세요.');
          }
        } else if (event.error === 'audio-capture') {
          setError('마이크를 찾을 수 없습니다. 마이크 연결을 확인해주세요.');
        } else if (event.error === 'not-allowed') {
          setError('마이크 사용 권한이 거부되었습니다. 브라우저 설정에서 마이크 권한을 허용해주세요.');
        } else {
          setError(`음성 인식 오류: ${event.error}`);
        }
        setIsListening(false);
      };
    }

    return recognitionInstance;
  }, [onTranscript, glossary]);

  const startRecognition = useCallback(() => {
    const recognition = initializeSpeechRecognition();
    if (recognition && !isListening) {
      try {
        recognition.start();
        console.log('음성 인식 시작 요청됨');
        setError(null);
      } catch (err) {
        console.error('음성 인식 시작 오류:', err);
        setError('음성 인식을 시작할 수 없습니다. 페이지를 새로고침하고 다시 시도해주세요.');
        setIsListening(false);
      }
    }
  }, [initializeSpeechRecognition, isListening]);

  const stopRecognition = useCallback(() => {
    if (recognitionInstance && isListening) {
      try {
        recognitionInstance.stop();
        console.log('음성 인식 중지 요청됨');
        restartAttempts.current = 0;
        if (rafIdRef.current) {
          cancelAnimationFrame(rafIdRef.current);
        }
      } catch (err) {
        console.error('음성 인식 중지 오류:', err);
      }
    }
  }, [isListening]);

  useEffect(() => {
    initializeSpeechRecognition();
    initializeAudio();

    return () => {
      if (recognitionInstance) {
        recognitionInstance.stop();
      }
      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [initializeSpeechRecognition]);

  const toggleListening = () => {
    if (isListening) {
      stopRecognition();
    } else {
      restartAttempts.current = 0;
      startRecognition();
    }
  };

  const handleAddGlossaryItem = () => {
    if (newGlossaryItem.trim() !== '') {
      setGlossary([...glossary, newGlossaryItem.trim()]);
      setNewGlossaryItem('');
    }
  };

  return (
    <div className="speech-recognition">
      <button onClick={toggleListening} className={isListening ? 'listening' : ''}>
        {isListening ? '음성 인식 중지' : '음성 인식 시작'}
      </button>
      <p>마이크: {isListening ? '켜짐' : '꺼짐'}</p>
      <canvas ref={canvasRef} width="300" height="100" style={{ border: '1px solid black' }} />
      {error && <p className="error">{error}</p>}
      <div className="glossary-input">
        <input
          type="text"
          value={newGlossaryItem}
          onChange={(e) => setNewGlossaryItem(e.target.value)}
          placeholder="Add name to glossary"
        />
        <button onClick={handleAddGlossaryItem}>Add</button>
      </div>
      <div className="glossary-list">
        <h3>Glossary:</h3>
        <ul>
          {glossary.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SpeechRecognitionComponent;