import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

const firebaseConfig = {
  apiKey: "AIzaSyDqsOOXnGzguKatIi2Q_4BSueEgXB7SELc",
  authDomain: "the-bolet.firebaseapp.com",
  databaseURL: "https://the-bolet-default-rtdb.firebaseio.com",
  projectId: "the-bolet",
  storageBucket: "the-bolet.appspot.com",
  messagingSenderId: "971451495529",
  appId: "1:971451495529:web:f9b1305fc3ebecd6e31ef8",
  measurementId: "G-5S9JHZBX7L"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const vertexAI = getVertexAI(app);
const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

export { app, analytics, model };