import { model } from '../config/firebase';

export async function translateWithGemini(text: string, targetLanguage: string, glossary: string[] = []): Promise<string> {
  const glossaryPrompt = glossary.length > 0
    ? `\n\nPlease pay special attention to the following names and ensure they are correctly transcribed and translated:\n${glossary.join(', ')}`
    : '';

  const prompt = `Translate the following text from Korean to ${targetLanguage}. Adhere to these guidelines:
1. Provide a concise and accurate translation without any additional explanations or comments.
2. Maintain the original meaning, tone, and level of formality as closely as possible.
3. Do not add interpretations or cultural notes.
4. If there are any ambiguities, use the most common or neutral interpretation.
5. Translate idioms and expressions to their closest ${targetLanguage} equivalents without explaining their meaning.${glossaryPrompt}

Text to translate:
${text}`;

  try {
    const result = await model.generateContent(prompt);
    const response = result.response;
    return response.text();
  } catch (error) {
    console.error('Error translating with Gemini:', error);
    throw error;
  }
}